import React, { useRef, useState } from 'react';
import { View, Text, Animated, TouchableOpacity, TextInput, Image } from 'react-native';
import {
    AntDesign, MaterialCommunityIcons
} from '@expo/vector-icons';
import { Dialog } from 'react-native-paper';
import OptionGroups from './OptionGroups';

import * as cartAction from '../../store/actions/cart';
import { useDispatch } from 'react-redux';

const ItemDialog = (props) => {

    const curruntItem = props.curruntItem;
    const hideModal = props.hideModal;
    const headerOpacity = useRef(new Animated.Value(0)).current;
    const zIndex = useRef(new Animated.Value(0)).current;

    const dispatch = useDispatch();

    const scrollRef = useRef();

    const [itemToBeAdded, setItemToBeAdded] = useState({
        restaurantInfoId: curruntItem.restaurantInfoId,
        quantity: 1,
        name: curruntItem.name,
        cnName: curruntItem.cnName,
        price: curruntItem.price,
        itemId: curruntItem.id,
        orderItemsOptions: [],
        categoryId: curruntItem.categoryId,
        categoryName: props.category.categoryName,
        categoryCnName: props.category.categoryCnName,
        specialInstructions: null,
        printerName: curruntItem.printerName,
        printable: curruntItem.printable,
        mOrderItem: null,
        taxExempt: false
    });
    const [currentOptionsList, setCurrentOptionsList] = useState(curruntItem.optionsList);


    const calculateItemSubTotal = () => {
        let total = 0.00;
        total = total + itemToBeAdded.price;
        itemToBeAdded.orderItemsOptions.forEach(e => {
            total = total + e.price * e.quantity
        })
        total = total * itemToBeAdded.quantity;
        return total.toFixed(2);
    }

    const verifyOptions = () => {
        let invalidOptions = [];
        currentOptionsList.forEach((currentOption) => {
            document.getElementById(currentOption.groupId + "_Label").style.backgroundColor = "white";
            if (currentOption.required) {
                let optionsAdded = 0;
                itemToBeAdded.orderItemsOptions.forEach((orderItemsOption) => {
                    if (orderItemsOption.groupId === currentOption.groupId) {
                        optionsAdded = optionsAdded + orderItemsOption.quantity;
                    }
                });

                if (optionsAdded < currentOption.numOfChoice) {
                    invalidOptions.push(currentOption);
                }
            }
        });

        if (invalidOptions.length > 0) {
            scrollRef.current.scrollTo({ x: 0, y: document.getElementById(invalidOptions[0].groupId).getAttribute('positiony') - 100 })
            invalidOptions.forEach((invalidOption) => {
                document.getElementById(invalidOption.groupId + "_Label").style.backgroundColor = "yellow";
            });
        }

        return invalidOptions.length == 0;
    }

    //console.log(selectecOptionGroups);

    return (
        <View style={{ flex: 1 }}>
            <Animated.View style={{
                height: 56,
                backgroundColor: 'white',
                opacity: headerOpacity,
                zIndex: zIndex,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                position: 'absolute',
                top: 0, left: 0, right: 0,
                flexDirection: 'row',
                alignItems: 'center',
                borderBottomColor: '#e7e7e7',
                borderBottomWidth: 1,
                shadowRadius: 15,
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowColor: '#000000',
                elevation: 1,
                shadowOpacity: 0.2,
            }}>
                <TouchableOpacity style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center', marginLeft: 16 }} onPress={() => { hideModal() }}>
                    <AntDesign name='close' size={24} color='#494949' />
                </TouchableOpacity>
                <Text style={{ color: 'black', fontSize: 18, fontWeight: 'bold', lineHeight: 24, marginLeft: 16 }}>{curruntItem && curruntItem.name}</Text>
            </Animated.View>
            <Dialog.ScrollArea style={{ borderTopWidth: 0 }}>
                <Animated.ScrollView ref={scrollRef} showsVerticalScrollIndicator={false} scrollEventThrottle={16} style={{ paddingVertical: 16, }}
                    onScroll={(event) => {
                        if (event.nativeEvent.contentOffset.y > 20) {
                            headerOpacity.setValue(event.nativeEvent.contentOffset.y / 30);
                            zIndex.setValue(100);
                        } else {
                            headerOpacity.setValue(0);
                            zIndex.setValue(-100);
                        }

                    }}
                >
                    <TouchableOpacity style={{ justifyContent: 'center', width: 24 }} onPress={() => { hideModal() }}>
                        <AntDesign name='close' size={24} color='#494949' />
                    </TouchableOpacity>
                    <Text style={{ color: 'black', fontSize: 32, fontWeight: 'bold', lineHeight: 40 }}>{curruntItem && curruntItem.name.trim()}</Text>
                    <Text style={{ fontSize: 16, fontWeight: '500', lineHeight: 22, color: '#767676', marginTop: 8 }}>{curruntItem && curruntItem.description && curruntItem.description.trim()}</Text>
                    {/* {curruntItem && curruntItem.itemImage !== 'https://www.etapthru.com/img/itemImage.png' && curruntItem.itemImage.trim() !== '' && <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-start', marginTop: 20 }}>
                        <Image style={{ flexShrink: 1, width: '100%', height: 250, borderRadius: 4 }} resizeMode='cover' source={{
                            uri: curruntItem && curruntItem.itemImage,
                        }} />
                    </View>} */}

                    <View style={{ marginTop: 30 }}>
                        <OptionGroups options={curruntItem && curruntItem.optionsList} setItemToBeAdded={setItemToBeAdded} category={props.category} />
                    </View>

                    <View>
                        <Text style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 22, marginBottom: 16 }}>Special Instructions</Text>
                        <TextInput style={{
                            outline: 'none', fontSize: 16, borderColor: '#e7e7e7', borderWidth: 1, minHeight: 96, borderRadius: 4, backgroundColor: 'white',
                            paddingVertical: 12, paddingHorizontal: 24, fontWeight: '500', lineHeight: 24
                        }} multiline numberOfLines={4} placeholder={'notes to let restaurant know'} />
                    </View>



                </Animated.ScrollView>
            </Dialog.ScrollArea>
            <View style={{
                flexDirection: 'row',
                paddingVertical: 10,
                paddingHorizontal: 20,
                justifyContent: 'flex-end',
                alignItems: 'center',
                shadowRadius: 15,
                shadowOffset: {
                    width: 0,
                    height: -1,
                },
                shadowColor: '#000000',
                elevation: 1,
                shadowOpacity: 0.2,
                height: 72
            }}>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                        if (itemToBeAdded.quantity > 1) {
                            setItemToBeAdded(prevState => {
                                return { ...prevState, quantity: prevState.quantity - 1 }
                            })
                            calculateItemSubTotal();
                        }
                    }}>
                        <AntDesign name='minuscircleo' size={24} color='#494949' />
                    </TouchableOpacity>
                    <TextInput maxLength={3} value={itemToBeAdded.quantity} style={{
                        // marginHorizontal: 16,
                        paddingVertical: 8,
                        paddingHorizontal: 16,
                        minHeight: 40,
                        borderWidth: 1,
                        borderColor: '#e7e7e7',
                        borderRadius: 4,
                        textAlign: 'center',
                        fontSize: 16,
                        fontWeight: '500',
                        outline: 'none',
                        width: 64

                    }} />
                    <TouchableOpacity style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                        setItemToBeAdded(prevState => {
                            return { ...prevState, quantity: prevState.quantity + 1 }
                        });
                        calculateItemSubTotal();
                    }}>
                        <AntDesign name='pluscircleo' size={24} color='#494949' />
                    </TouchableOpacity>
                </View>

                <TouchableOpacity style={{ backgroundColor: '#4285F4', paddingVertical: 8, paddingHorizontal: 16, minHeight: 40, borderRadius: 10000, justifyContent: 'center', marginLeft: 16 }}
                    onPress={() => {
                        if (verifyOptions()) {
                            if (localStorage.localCart && itemToBeAdded.restaurantInfoId !== localStorage.localCart.restaurantInfoId) {
                                localStorage.removeItem("localCart");
                            }
                            dispatch(cartAction.addToCart(itemToBeAdded));
                            hideModal();
                            setTimeout(() => {
                                props.scrollRef.current.scrollTo({ x: 0, y: 10000000 })
                                // props.rePositionSelectedRow(0);
                            }, 250);
                        }
                    }}>
                    <Text style={{ color: 'white', fontSize: 16, fontWeight: 'bold', lineHeight: 22 }}>Add to cart - ${calculateItemSubTotal()}</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default ItemDialog;