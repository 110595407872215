import React, { useState } from 'react'
import { Text, View, TouchableOpacity, TextInput, Image } from 'react-native'
import { useSelector } from 'react-redux'
import { Ionicons } from '@expo/vector-icons'
import Constant from "../until/constant";
import { TextInput as PaperTextInput } from 'react-native-paper'
import CreditCard from 'creditcards'
import valid from 'card-validator';
const Index = (props) => {

    const sendMessage = props.sendMessage
    const order = useSelector(state => state.cart)
    const [payCard, setPayCard] = useState(false)

    const formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };

    const [cardInfo, setCardInfo] = useState({
        cardholder: order.customer.firstName + ' ' + order.customer.lastName,
        cardNumber: null,
        expdate: null,
        cvv: null,
        zipcode: null,
    });

    const [validCardholder, setValidCardholder] = useState(true);

    const [validCreditCardNumber, setValidCreditCardNumber] = useState(false);

    const [validCvv, setValidCvv] = useState(false);

    const [validExpDate, setValidExpDate] = useState(false);

    const [validZipCode, setValidZipCode] = useState(false);

    const [expDate, setExpDate] = useState();

    const [cardType, setCardType] = useState("credit-card");

    const Visa = require("../assets/Visa.svg");
    const MasterCard = require("../assets/MasterCard.svg");
    const Discover = require("../assets/Discover.svg");
    const AmEx = require("../assets/AmericanExpress.svg");
    const blankCard = require("../assets/creditCard.svg");

    const [ccInfo, setCcInfo] = useState({
        cardholder: order.customer.firstName + ' ' + order.customer.lastName,
        cardnumber: null,
        expdate: null,
        cvv: null,
        zipcode: null,
    });

    const setUpdatedExpDate = (updatedExpDate) => {
        let expdateValidation = valid.expirationDate(updatedExpDate);
        setValidExpDate(expdateValidation.isValid);
        if (expdateValidation.isValid) {
            var month = expdateValidation.month;

            if (month.length < 2) {
                month = "0" + month;
            }

            var year = expdateValidation.year;

            if (year.length > 2) {
                year = year.substr(year.length - 2, 2);
            }

            // console.log("exp date: " + month + "/" + year);

            setCcInfo(prevState => {
                return {
                    ...prevState,
                    expdate: month + "/" + year
                }
            });
        }
    }

    const expDateTextChanged = (text) => {
        let expdateValidation = valid.expirationDate(text);
        if (expdateValidation.isValid) {
            setTimeout(() => {
                setExpDate(text);
                setUpdatedExpDate(text);
            }, 100);
        }
    }

    const changeExpDate = (event) => {
        var key = event.nativeEvent.key;
        if ('0123456789/'.includes(key)) {
            setTimeout(() => {
                let rawValue = expDate ? expDate.replace('/', '') : '';

                let updatedExpDate;

                if (rawValue.length < 2) {
                    updatedExpDate = rawValue + key;
                } else if (rawValue.length === 2) {
                    if (key === "/") {
                        updatedExpDate = rawValue + "/";
                    } else {
                        updatedExpDate = rawValue.substr(0, 1) + "/" + rawValue.substr(1, 1) + key;
                    }
                } else if (rawValue.length === 3) {
                    if (key !== "/") {
                        updatedExpDate = rawValue.substr(0, 2) + "/" + rawValue.substr(2, 1) + key;
                    }
                } else if (rawValue.length === 4) {
                    if (key !== "/") {
                        updatedExpDate = rawValue.substr(0, 2) + "/" + rawValue.substr(2, 2);
                    }
                } else {
                    updatedExpDate = rawValue;
                }
                setExpDate(updatedExpDate);
                setUpdatedExpDate(updatedExpDate);
            }, 100);
        } else if (key === 'Backspace') {
            setTimeout(() => {
                let rawValue = expDate ? expDate.replace('/', '') : '';

                let updatedExpDate;

                if (rawValue.length === 1) {
                    updatedExpDate = '';
                } else if (rawValue.length === 2) {
                    updatedExpDate = rawValue.substr(0, 1);
                } else if (rawValue.length === 3) {
                    updatedExpDate = rawValue.substr(0, 2);
                } else if (rawValue.length === 4) {
                    updatedExpDate = rawValue.substr(0, 1) + "/" + rawValue.substr(2, 2);
                }
                setExpDate(updatedExpDate);
                setUpdatedExpDate(updatedExpDate);
            }, 100);
        }
    }

    const saveOrderToServer = async () => {
        // final calculation before sending order to server!
        const tempLocalOrder = JSON.parse(localStorage.localCart);
        if (tempLocalOrder.scheduleTime) {
            tempLocalOrder.scheduleTime = tempLocalOrder.scheduleTime.replace(' ', 'T')
        }
        if (tempLocalOrder.orderType == 'DELIVERY' && order && order.deliveryFee) {
            tempLocalOrder.deliveryFee = order.deliveryFee;
        }
        fetch(`${Constant.endPointUrl}/secure/api/tapthru/calculateOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `orderjson=${encodeURIComponent(JSON.stringify(tempLocalOrder))}`
        }).then(response => response.json())
            .then(responseText => {

                // once got the calculation back from server, send order!

                try {
                    const paymentStatus = (payCard) ? "CREDIT": "CASH";

                    const dataBody = `creditCardInfo=${JSON.stringify(ccInfo)}&order=${encodeURIComponent(JSON.stringify(responseText.data))}&paymentstatus=${paymentStatus}`;

                    fetch(`${Constant.endPointUrl}/secure/api/tapthru/sendOrderV2`, {

                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: dataBody
                    }).then(response => response.json())
                        .then(responseText => {
                            console.log(responseText)
                            if (responseText.status === 200) {
                                // props.history.push('orderSuccess', {orderId: responseText.data.orderId, total: responseText.data.total})
                                alert(responseText.message);
                                console.log('orderSuccess');
                                localStorage.removeItem("localCart");
                                document.location = "/";
                            } else {
                                sendMessage(responseText.message);
                            }
                        });

                } catch (e) {

                    console.log(e)
                }
            });
    }

    return (
        <View style={{ backgroundColor: '#4285F4', zIndex: 100000, minHeight: 157, maxWidth: 450, width: 1000, padding: 16, borderBottomLeftRadius: 25, boxShadow: 'rgb(0 0 0 / 30%) -8px 14px 20px 2px' }} >

            <Text style={{ color: 'black', marginBottom: 16, fontWeight: 'bold', fontSize: 20 }}>CHECKOUT</Text>
            <View style={{ borderBottomWidth: 1, paddingBottom: 10 }} >
                <View style={{ flexDirection: 'row', width: '100%' }} >
                    <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#fff', paddingVertical: 5, width: '50%' }} >First Name: {order.customer.firstName}</Text>
                    <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#fff', paddingVertical: 5, width: '50%' }} >Last Name:  {order.customer.lastName}</Text>
                </View>
                <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#fff', paddingVertical: 5 }} >Phone Number:  {formatPhoneNumber(order.customer.phoneNumber)}</Text>
                {order.customer.address && order.orderType == 'DELIVERY' && <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#fff', paddingVertical: 5 }} >Address: {order.customer.address}, {order.customer.city} {order.customer.state} {order.customer.zip}</Text>}
                {order.customer.address2 && order.orderType == 'DELIVERY' && <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#fff', paddingVertical: 5 }} >Apt/floor/suite: {order.customer.address2}</Text>}
            </View>
            <View style={{ flexDirection: 'column', marginTop: 15, justifyContent: 'space-between' }} >
                <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#fff', marginBottom: 16 }} >Payment:</Text>
                <View style={{ flexDirection: 'row', width: '100%', marginBottom: 16 }} >
                    <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }}
                        onPress={() => {
                            setPayCard(false)
                        }}
                    >
                        <Ionicons name={!payCard ? 'radio-button-on' : "radio-button-off-sharp"} size={24} color="#fff" style={{ marginTop: 3 }} />
                        <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }} >Pay In Store</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 16 }}
                        onPress={() => {
                            setPayCard(true)
                        }}
                    >
                        <Ionicons name={payCard ? 'radio-button-on' : "radio-button-off-sharp"} size={24} color="#fff" style={{ marginTop: 3 }} />
                        <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }} >Pay with Card</Text>
                    </TouchableOpacity>
                </View>
            </View>
            {
                payCard ?
                    <View style={{ marginTop: 10 }} >
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                            <View style={{ width: '100%' }}>
                                <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5 }} >Card Holder</Text>
                                <PaperTextInput
                                    autoCompleteType="name"
                                    theme={{
                                        colors: {
                                            primary: (cardInfo && cardInfo.cardholder && validCardholder) ? '#5fff5f'
                                                : cardInfo && cardInfo.cardholder && cardInfo.cardholder.trim().length > 0 && !validCardholder ? "#FF5F5FFF" : '#FFFFFF',
                                            background: 'white',
                                            text: 'white'
                                        }
                                    }}
                                    outlineColor={(cardInfo && cardInfo.cardholder && validCardholder) ? '#5fff5f'
                                        : cardInfo && cardInfo.cardholder && cardInfo.cardholder.trim().length > 0 && !validCardholder ? "#FF5F5FFF" : '#ffffff'}
                                    style={{
                                        marginBottom: 15,
                                        padding: 5,
                                        fontWeight: 'bold',
                                        borderBottomWidth: 1,
                                        borderColor: (cardInfo && cardInfo.cardholder && validCardholder) ? '#5fff5f'
                                            : cardInfo && cardInfo.cardholder && cardInfo.cardholder.trim().length > 0 && !validCardholder ? "#FF5F5FFF" : '#FFFFFF',
                                        backgroundColor: '#4285F4',
                                        outline: (cardInfo && cardInfo.cardholder && validCardholder) ? '#5fff5f'
                                            : cardInfo && cardInfo.cardholder && cardInfo.cardholder.trim().length > 0 && !validCardholder ? "#FF5F5FFF" : '#FFFFFF',
                                        fontSize: 16,
                                        color: '#4285F4',
                                        width: '95%',
                                        height: 21,
                                        marginHorizontal: 10
                                    }}
                                    placeholder='Cardholder Name'
                                    placeholderTextColor='#AAAAAA'
                                    value={ccInfo.cardholder}
                                    onChangeText={text => {
                                        setValidCardholder(text.length > 0);
                                        setCcInfo(prevState => {
                                            return {
                                                ...prevState,
                                                cardholder: text
                                            };
                                        })
                                    }}
                                />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                            <View style={{ width: '70%' }}>
                                <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5 }} >Card Number</Text>
                                <PaperTextInput
                                    autoCompleteType="cc-number"
                                    theme={{
                                        colors: {
                                            primary: (cardInfo && cardInfo.cardNumber && validCreditCardNumber) ? '#5fff5f'
                                                : cardInfo && cardInfo.cardNumber && cardInfo.cardNumber.trim().length > 0 && !validCreditCardNumber ? "#FF5F5FFF" : '#ffffff',
                                            background: '#4285F4',
                                            text: '#ffffff',
                                            borderColor: '#ffffff'
                                        }
                                    }}
                                    outlineColor={(cardInfo && cardInfo.cardNumber && validCreditCardNumber) ? '#5fff5f'
                                        : cardInfo && cardInfo.cardNumber && cardInfo.cardNumber.trim().length > 0 && !validCreditCardNumber ? "#FF5F5FFF" : '#ffffff'}
                                    style={{
                                        marginBottom: 15,
                                        padding: 5,
                                        fontWeight: 'bold',
                                        borderBottomWidth: 1,
                                        borderColor: (cardInfo && cardInfo.cardNumber && validCreditCardNumber) ? '#5fff5f'
                                            : cardInfo && cardInfo.cardNumber && cardInfo.cardNumber.trim().length > 0 && !validCreditCardNumber ? "#FF5F5FFF" : '#ffffff',
                                        backgroundColor: '#4285F4',
                                        outline: (cardInfo && cardInfo.cardNumber && validCreditCardNumber) ? '#5fff5f'
                                            : cardInfo && cardInfo.cardNumber && cardInfo.cardNumber.trim().length > 0 && !validCreditCardNumber ? "#FF5F5FFF" : '#ffffff',
                                        fontSize: 16,
                                        color: '#4285F4',
                                        width: '95%',
                                        height: 21,
                                        marginHorizontal: 10
                                    }}
                                    placeholderTextColor='#AAAAAA'
                                    placeholder='0000 0000 0000 0000'
                                    value={cardInfo && CreditCard.card.format(cardInfo.cardNumber, ' ')}
                                    onChangeText={text => {
                                        text = text.replace(/\s/g, '');

                                        if (text.startsWith("3") && text.length > 15) {
                                            text = text.substr(0, 15);
                                        } else if (text.length > 16) {
                                            text = text.substr(0, 16);
                                        }
                                        var numberValidation = valid.number(text);
                                        setValidCreditCardNumber(numberValidation.isValid);

                                        var cardType;

                                        if (numberValidation.card) {
                                            switch (numberValidation.card.type) {
                                                case "visa":
                                                    cardType = Visa;
                                                    break;
                                                case "mastercard":
                                                    cardType = MasterCard;
                                                    break;
                                                case "american-express":
                                                    cardType = AmEx;
                                                    break;
                                                case "discover":
                                                    cardType = Discover;
                                                    break;
                                                default:
                                                    cardType = blankCard;
                                            }
                                        } else {
                                            cardType = blankCard;
                                        }
                                        setCardType(cardType);
                                        if (numberValidation.isValid) {
                                            setCcInfo(prevState => {
                                                return {
                                                    ...prevState,
                                                    cardnumber: text
                                                }
                                            });
                                        }
                                        setCardInfo(prev => {
                                            return { ...prev, cardNumber: text }
                                        })
                                        var cvvValidation = valid.cvv(cardInfo.cvv, text.startsWith("3") ? 4 : 3);
                                        setValidCvv(cvvValidation.isValid);
                                    }}
                                />
                            </View>

                            <View style={{ width: '25%' }} >
                                <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5 }} >CVV</Text>
                                <PaperTextInput
                                    theme={{
                                        colors: {
                                            primary: (cardInfo && cardInfo.cvv && validCvv) ? '#5fff5f'
                                                : cardInfo && cardInfo.cvv && cardInfo.cvv.trim().length > 0 && !validCvv ? "#FF5F5FFF" : '#ffffff',
                                            background: '#4285F4',
                                            text: '#ffffff',
                                            borderColor: (cardInfo && cardInfo.cvv && validCvv) ? '#5fff5f'
                                                : cardInfo && cardInfo.cvv && cardInfo.cvv.trim().length > 0 && !validCvv ? "#FF5F5FFF" : '#ffffff'
                                        }
                                    }}
                                    autoCompleteType="cc-csc"
                                    maxLength={cardInfo && cardInfo.cardNumber && cardInfo.cardNumber.trim().length > 0 && cardInfo.cardNumber.trim().startsWith("3") ? 4 : 3}
                                    outlineColor={(cardInfo && cardInfo.cvv && validCvv) ? '#5fff5f'
                                        : cardInfo && cardInfo.cvv && cardInfo.cvv.trim().length > 0 && !validCvv ? "#FF5F5FFF" : '#ffffff'}
                                    style={{
                                        marginBottom: 15,
                                        padding: 5,
                                        fontWeight: 'bold',
                                        borderBottomWidth: 1,
                                        borderColor: (cardInfo && cardInfo.cvv && validCvv) ? '#5fff5f'
                                            : cardInfo && cardInfo.cvv && cardInfo.cvv.trim().length > 0 && !validCvv ? "#FF5F5FFF" : '#ffffff',
                                        backgroundColor: '#4285F4',
                                        outline: (cardInfo && cardInfo.cvv && validCvv) ? '#5fff5f'
                                            : cardInfo && cardInfo.cvv && cardInfo.cvv.trim().length > 0 && !validCvv ? "#FF5F5FFF" : '#ffffff',
                                        fontSize: 16,
                                        color: '#4285F4',
                                        width: '85%',
                                        height: 21,
                                        marginHorizontal: 10
                                    }}
                                    placeholder='000'
                                    placeholderTextColor='#AAAAAA'
                                    onChangeText={text => {
                                        var cvvValidation = valid.cvv(text, cardInfo && cardInfo.cardNumber && cardInfo.cardNumber.trim().startsWith('3') ? 4 : 3);
                                        setValidCvv(cvvValidation.isValid);
                                        if (cvvValidation.isValid) {
                                            setCcInfo(prevState => {
                                                return {
                                                    ...prevState,
                                                    cvv: text
                                                }
                                            });
                                        }
                                        setCardInfo(prevState => {
                                            return {
                                                ...prevState,
                                                cvv: text
                                            }
                                        })
                                    }}
                                />
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                            <View>
                                <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5 }} >Expiration</Text>
                                <PaperTextInput
                                    theme={{
                                        colors: {
                                            primary: (expDate && validExpDate) ? '#5fff5f'
                                                : expDate && expDate.trim().length > 0 && !validExpDate ? "#FF5F5FFF" : '#ffffff',
                                            text: '#ffffff',
                                            borderColor: (expDate && validExpDate) ? '#5fff5f'
                                                : expDate && expDate.trim().length > 0 && !validExpDate ? "#FF5F5FFF" : '#ffffff'
                                        }
                                    }}
                                    style={{
                                        marginBottom: 15,
                                        padding: 5,
                                        fontWeight: 'bold',
                                        borderBottomWidth: 1,
                                        borderColor: (expDate && validExpDate) ? '#5fff5f'
                                            : expDate && expDate.trim().length > 0 && !validExpDate ? "#FF5F5FFF" : '#ffffff',
                                        backgroundColor: '#4285F4',
                                        outline: (expDate && validExpDate) ? '#5fff5f'
                                            : expDate && expDate.trim().length > 0 && !validExpDate ? "#FF5F5FFF" : '#ffffff',
                                        fontSize: 16,
                                        color: 'white',
                                        width: '80%',
                                        height: 21,
                                        marginHorizontal: 10
                                    }}
                                    outlineColor={(expDate && validExpDate) ? '#5fff5f'
                                        : expDate && expDate.trim().length > 0 && !validExpDate ? "#FF5F5FFF" : '#ffffff'}
                                    autoCompleteType="cc-exp"
                                    placeholder='MM/YY'
                                    placeholderTextColor='#AAAAAA'
                                    onKeyPress={changeExpDate}
                                    onChangeText={expDateTextChanged}
                                    value={expDate}
                                />
                            </View>

                            <View style={{}} >
                                <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5 }} >Zip Code</Text>
                                <PaperTextInput
                                    theme={{
                                        colors: {
                                            primary: (cardInfo && cardInfo.zipcode && validZipCode) ? '#5fff5f'
                                                : cardInfo && cardInfo.zipcode && cardInfo.zipcode.trim().length > 0 && !validZipCode ? "#FF5F5FFF" : '#ffffff',
                                            text: '#ffffff',
                                            borderColor: (cardInfo && cardInfo.zipcode && validZipCode) ? '#5fff5f'
                                                : cardInfo && cardInfo.zipcode && cardInfo.zipcode.trim().length > 0 && !validZipCode ? "#FF5F5FFF" : '#ffffff'
                                        }
                                    }}
                                    maxLength={5}
                                    style={{
                                        marginBottom: 15,
                                        padding: 5,
                                        fontWeight: 'bold',
                                        borderBottomWidth: 1,
                                        borderColor: (cardInfo && cardInfo.zipcode && validZipCode) ? '#5fff5f'
                                            : cardInfo && cardInfo.zipcode && cardInfo.zipcode.trim().length > 0 && !validZipCode ? "#FF5F5FFF" : '#ffffff',
                                        backgroundColor: '#4285F4',
                                        outline: (cardInfo && cardInfo.zipcode && validZipCode) ? '#5fff5f'
                                            : cardInfo && cardInfo.zipcode && cardInfo.zipcode.trim().length > 0 && !validZipCode ? "#FF5F5FFF" : '#ffffff',
                                        fontSize: 16,
                                        color: 'white',
                                        width: '50%',
                                        height: 21,
                                        marginHorizontal: 10
                                    }}
                                    outlineColor={(cardInfo && cardInfo.zipcode && validZipCode) ? '#5fff5f'
                                        : cardInfo && cardInfo.zipcode && cardInfo.zipcode.trim().length > 0 && !validZipCode ? "#FF5F5FFF" : '#ffffff'}
                                    autoCompleteType="postal_code"
                                    placeholder='00000'
                                    placeholderTextColor='#AAAAAA'
                                    onChangeText={text => {
                                        var zipcodeValidation = valid.postalCode(text, {minLength: 5});
                                        setValidZipCode(zipcodeValidation.isValid);
                                        if (zipcodeValidation.isValid) {
                                            setCcInfo(prevState => {
                                                return {
                                                    ...prevState,
                                                    zipcode: text
                                                }
                                            });
                                        }
                                        setCardInfo(prevState => {
                                            return {
                                                ...prevState,
                                                zipcode: text
                                            }
                                        });
                                    }}
                                />
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }} >
                            <View>
                                <Image source={Visa} style={{
                                    width: 60,
                                    height: 45,
                                    opacity: cardType === Visa ? 1.0 : 0.5
                                }} />
                            </View>

                            <View style={{}} >
                                <Image source={MasterCard} style={{
                                    width: 60,
                                    height: 45,
                                    display: "flex",
                                    opacity: cardType === MasterCard ? 1.0 : 0.5
                                }} />
                            </View>

                            <View style={{}} >
                                <Image source={Discover} style={{
                                    width: 60,
                                    height: 45,
                                    display: "flex",
                                    opacity: cardType === Discover ? 1.0 : 0.5
                                }} />
                            </View>

                            <View style={{}} >
                                <Image source={AmEx} style={{
                                    width: 60,
                                    height: 45,
                                    display: "flex",
                                    opacity: cardType === AmEx ? 1.0 : 0.5
                                }} />
                            </View>
                        </View>
                    </View>
                    : <></>
            }

            <TouchableOpacity
                style={{
                    width: '100%',
                    alignItems: 'center',
                    backgroundColor: '#000',
                    borderRadius: 10000,
                    marginTop: 10,
                    opacity: (!payCard
                        || (payCard && validCreditCardNumber && validCvv && validExpDate && validZipCode)) ? 1.0 : 0.5
                }}
                onPress={() => {
                    saveOrderToServer()
                }}
                disabled={(!validCvv && payCard) || (!validExpDate && payCard) || (!validCreditCardNumber && payCard) || (payCard && !validZipCode)}
            >
                <Text style={{fontSize: 16, color: '#fff', fontWeight: 'bold', paddingVertical: 10}}>Place Order</Text>
            </TouchableOpacity>
        </View>
    )
}

export default Index