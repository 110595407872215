import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSelector, useDispatch } from 'react-redux';
import * as cartAction from '../../store/actions/cart';
import {
    Entypo, AntDesign
} from '@expo/vector-icons';
const Index = (props) => {
    const order = useSelector(state => state.cart);
    const dispatch = useDispatch();
    const [itemTotalRows, setItemTotalRows] = useState(0);
    const [editPriceShow, setEditPriceShow] = useState(false);
    const [defaultValue, setDefaultValue] = useState(0)
    useEffect(() => {

        if (order.orderItems.length > 0) {
            if (order.orderItems.length > itemTotalRows) {
                const optionIndex = order.orderItems[order.orderItems.length - 1].orderItemsOptions.length > 0 ? order.orderItems[order.orderItems.length - 1].orderItemsOptions.length - 1 : -1;
                props.setSelectedRowId(`${order.orderItems.length - 1},${optionIndex}`);
                optionIndex == -1 ? setDefaultValue(order.orderItems[order.orderItems.length - 1].price) : setDefaultValue(order.orderItems[order.orderItems.length - 1].orderItemsOptions[optionIndex].price);
            } else if (order.orderItems.length < itemTotalRows) {
                setItemTotalRows(itemTotalRows - 1);
                if (itemTotalRows > 2 && props.selectedRowId) {
                    parseInt(props.selectedRowId.split(',')[0]) > 0 ? props.setSelectedRowId(`${parseInt(props.selectedRowId.split(',')[0]) - 1},-1`) : props.setSelectedRowId(`0,-1`);
                } else if (itemTotalRows > 0) {
                    props.setSelectedRowId(`${Math.max(0, itemTotalRows - 2)},-1`);
                } else {
                    props.setSelectedRowId(null);
                }
                (order.orderItems.length > 0 & props.selectedRowId) && setDefaultValue(order.orderItems[Math.max(0, itemTotalRows - 2)].price)
            } else {
                if (props.selectedRowId) {
                    let currentOptionIndex = parseInt(props.selectedRowId.split(',')[1]);
                    if (currentOptionIndex > order.orderItems[props.selectedRowId.split(',')[0]].orderItemsOptions.length - 1) {
                        props.setSelectedRowId(props.selectedRowId.split(',')[0] + `,${currentOptionIndex - 1}`);
                        currentOptionIndex > 0 && setDefaultValue(order.orderItems[props.selectedRowId.split(',')[0]].orderItemsOptions[currentOptionIndex - 1].price);
                    }
                }
            }
        }
    }, [order])

    return (
        <View style={{ maxWidth: 334, borderWidth: 1, borderColor: '#6666', borderTopWidth: 0, zIndex: 1 }}>
            <View style={{ flexDirection: 'row', paddingHorizontal: 8, borderBottomWidth: 1, borderBottomColor: '#6666', justifyContent: 'center', alignItems: 'center', backgroundColor: '#4285F4' }}>
                <Text style={{ width: 30, color: 'white', fontWeight: 'bold' }}>Qty</Text>
                <Text style={{ flex: 1, borderLeftWidth: 1, borderRightWidth: 1, paddingLeft: 8, borderColor: '#6666', paddingVertical: 4, color: 'white', fontWeight: 'bold' }}>Name</Text>
                <Text style={{ width: 60, paddingLeft: 8, color: 'white', fontWeight: 'bold' }}>Price</Text>
            </View>

            <ScrollView ref={props.scrollRef} style={{ maxHeight: 500, height: 500 }} showsHorizontalScrollIndicator={false}>


                {order.orderItems.map((e, i) => {

                    return (
                        <View key={'oi' + i.toString()}>
                            <TouchableOpacity activeOpacity={1} style={{ cursor: 'pointer' }}
                                onLayout={() => {
                                    setItemTotalRows(i + 1);
                                }}
                                onPress={() => {
                                    props.setSelectedRowId(`${i},-1`);
                                    setDefaultValue(order.orderItems[i].price)
                                }}
                            >
                                <View style={{ flexDirection: 'row', paddingHorizontal: 8, borderBottomWidth: 1, borderColor: '#6666', backgroundColor: props.selectedRowId == `${i},-1` ? '#4285F4' : 'white', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ width: 30, fontWeight: 'bold', textAlign: 'center', color: props.selectedRowId == `${i},-1` ? 'white' : 'black' }}>{e.quantity}</Text>
                                    <Text style={{ flex: 1, borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#6666', paddingLeft: 8, fontWeight: '500', fontSize: 15, paddingVertical: 8, color: props.selectedRowId == `${i},-1` ? 'white' : 'black' }}>{e.name}</Text>
                                    <Text style={{ width: 60, paddingLeft: 8, fontWeight: '500', color: props.selectedRowId == `${i},-1` ? 'white' : 'black' }}>{(e.price * e.quantity).toFixed(2)}</Text>
                                </View>
                            </TouchableOpacity>
                            {e.orderItemsOptions.map((o, j) => {
                                return (
                                    <TouchableOpacity key={'oio' + j.toString()} activeOpacity={1} style={{ cursor: 'pointer' }}
                                        onPress={() => {
                                            props.setSelectedRowId(`${i},${j}`);
                                            setDefaultValue(order.orderItems[i].orderItemsOptions[j].price)
                                        }}
                                    >
                                        <View style={{ flexDirection: 'row', paddingHorizontal: 8, borderBottomWidth: 1, borderColor: '#6666', backgroundColor: props.selectedRowId == `${i},${j}` ? '#4285F4' : 'white', justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ width: 30, textAlign: 'center', color: props.selectedRowId == `${i},${j}` ? 'white' : 'rgb(118, 118, 118)' }}>{o.quantity}</Text>
                                            <Text style={{ flex: 1, borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#6666', paddingLeft: 32, fontWeight: '500', paddingVertical: 8, fontStyle: 'italic', color: props.selectedRowId == `${i},${j}` ? 'white' : 'rgb(118, 118, 118)' }}>{o.name}</Text>
                                            <Text style={{ width: 60, paddingLeft: 8, fontWeight: '500', color: props.selectedRowId == `${i},${j}` ? 'white' : 'rgb(118, 118, 118)' }}>{(o.price * o.quantity).toFixed(2)}</Text>
                                        </View>

                                    </TouchableOpacity>
                                )
                            })}
                        </View>
                    )

                })}
            </ScrollView>


            <View style={{ flexDirection: 'row', padding: 6, borderTopWidth: 1, borderColor: '#8888', width: '100%', }}>
                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity style={{ borderWidth: 1, width: 30, height: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 15, borderColor: '#8888' }} onPress={() => {
                        if (props.selectedRowId && itemTotalRows > 0) {
                            if (props.selectedRowId && props.selectedRowId.split(',')[1] == -1) {
                                try {
                                    dispatch(cartAction.reduceItemQuantity(props.selectedRowId))
                                } catch (e) {
                                    console.log(e);
                                }

                            } else {
                                dispatch(cartAction.reduceOptionQuantity(props.selectedRowId));
                            }
                        }
                    }}>
                        <Entypo name='minus' size={30} color='#4285F4' />
                    </TouchableOpacity>
                    <TouchableOpacity style={{ borderWidth: 1, width: 30, height: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 15, borderColor: '#8888', marginLeft: 10 }}
                        onPress={() => {
                            if (props.selectedRowId) {
                                if (props.selectedRowId && props.selectedRowId.split(',')[1] == -1) {
                                    dispatch(cartAction.addItemQuantity(props.selectedRowId))
                                } else {
                                    dispatch(cartAction.addOptionQuantity(props.selectedRowId))
                                }
                            }

                        }}>
                        <Entypo name='plus' size={30} color='#4285F4' />
                    </TouchableOpacity>
                </View>

                {/* edit price button */}
                <View style={{ flex: 1 }}>
                    <TouchableOpacity style={{ alignSelf: 'flex-end' }}
                        onPress={() => {
                            setEditPriceShow(prevState => {
                                return !prevState
                            })
                        }}>
                        <AntDesign name='edit' size={30} color={editPriceShow ? 'black' : '#4285F4'} />
                    </TouchableOpacity>
                </View>


            </View>

            {order && <View style={{ display: editPriceShow ? 'flex' : 'none', position: 'absolute', bottom: -115, left: 0, right: 0, backgroundColor: '#4285F4', height: 100, padding: 16, borderBottomLeftRadius: 25 }}>
                <View style={{ position: 'absolute', bottom: '100%', right: 0, width: 30, borderBottomWidth: 20, borderLeftWidth: 20, borderRightWidth: 20, borderLeftColor: 'transparent', borderRightColor: 'transparent', borderBottomColor: '#4285F4' }}>
                </View>
                <View>
                    <Text style={{ color: 'white', fontWeight: 'bold' }}>Edit price to selected item/option</Text>
                    <View style={{ flexDirection: 'row', flex: 1, marginTop: 16 }}>
                        <Text style={{ fontSize: 18, fontWeight: 'bold', alignSelf: 'flex-end', marginBottom: 20, color: 'white' }}>$</Text>
                        <TextInput
                            style={{ marginBottom: 15, paddingHorizontal: 5, fontWeight: 'bold', borderBottomWidth: 1, borderBottomColor: 'white', outline: 'none', width: '100%', fontSize: 18, marginHorizontal: 8, color: 'white' }}
                            value={defaultValue}
                            onChange={e => {
                                // only allow number and .
                                let text = e.nativeEvent.text.replace(/[^0-9\.]/g, '');
                                // if first number is zero, once entry, remove the leading zero
                                if (text.length == 2 && text[0] == '0') text = e.nativeEvent.data;
                                // only 1 . can be entered!
                                if (e.nativeEvent.data == '.') {
                                    if (text.split('.').length > 2) {
                                        text = text.slice(0, -1);
                                    } else {
                                        if (text.split('.')[0] == '') {
                                            text = '0.'
                                        }
                                    }

                                }
                                // only allow 2 digit after .
                                if (text.split('.').length == 2) {
                                    if (text.split('.')[1].length > 2) {
                                        text = text.slice(0, -1)
                                    }
                                }
                                setDefaultValue(text)
                            }}
                            onSubmitEditing={(e) => {
                                dispatch(cartAction.editPrice(props.selectedRowId, parseFloat(defaultValue)))
                                setEditPriceShow(false)
                            }}
                        />
                        <TouchableOpacity style={{ backgroundColor: 'black', height: 30, paddingHorizontal: 16, borderRadius: 1000, alignItems: 'center', justifyContent: 'center', marginBottom: 15 }}
                            onPress={() => {
                                dispatch(cartAction.editPrice(props.selectedRowId, parseFloat(defaultValue)));
                                setEditPriceShow(false);
                            }}
                        >
                            <Text style={{ color: 'white', fontWeight: 'bold', marginBottom: 4 }}>Apply</Text>
                        </TouchableOpacity>
                    </View>

                </View>


            </View>}
        </View>
    );
}

const styles = StyleSheet.create({

});

export default Index;